import React from "react";
import {useTranslation} from "react-i18next";

function StatisticTable (props:any) {
    const { t } = useTranslation()

    return (
        <>
            <div className="details-header">
                <span>{t('Статистика')}</span>
            </div>
            <div className="statistic-table-scroll">
            <table>
                <tr>
                    <th>{t('Адрес кошелька')}</th>
                    <th>{t('Собственные билеты')}</th>
                    <th>{t('Счет')}</th>
                    <th>{t('Идентификатор реферала')}</th>
                </tr>
                {props.tableData &&
                    props.tableData.map((val:any, key:any) => {
                        return(
                        <>
                        {val.tickets_arr.length?
                                <tr key={key}>
                                    <td>{`${val?.wallet_address.slice(0, 4)}...${val?.wallet_address.slice(-5)}`}</td>
                                    <td>{val.tickets_arr.length}</td>
                                    <td>{val.tickets_arr.length * 25}</td>
                                    <td>{val.referral_id_for_front}</td>
                                </tr>
                            :""
                        }
                        </>)

                    })}
            </table>
            </div>
        </>
    );
}

export default StatisticTable;
