import React, { useEffect, useState } from "react";
import SlideItems from "../slider/SlideItems";
import BuyButton from "../buttons/BuyButton";
import TicketButton from "../buttons/TicketButton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import buyBtnPrefix from "../assets/images/buy-btn-prefix.svg";
import AvatarImg from "../assets/images/avatar-img.svg";
import MegaJackpot from "../assets/images/mega-jackpot.svg";
import USDT2500 from "../assets/images/2500-usdt.svg";
import Assets1 from "../assets/images/assets1.svg";
import Assets2 from "../assets/images/assets2.svg";
import winnerUserLogo from "../assets/images/winner.png";
import Countdown from "react-countdown";
import Footer from "./Footer";

function AuthLayout() {
  const navigate = useNavigate();
  const [megaJackpotData, setMegaJackpotData] = useState("");
  const [megaJackpotTicketsCount, setMegaJackpotTicketsCount] = useState<any>();
  const [countdown, setCountdown] = useState<any>();
  const { t } = useTranslation();

  useEffect(() => {
    getApiData();
    getApiCountData();
    getApiCountDown();
  }, []);

  const audio = () => {
    new Audio(
      "https://codeskulptor-demos.commondatastorage.googleapis.com/pang/paza-moduless.mp3"
    ).play();
  };
  const DAYS =
    countdown?.day * 24 * 60 * 60 * 1000 +
    countdown?.hour * 60 * 60 * 1000 +
    countdown?.minute * 60 * 1000 +
    countdown?.second * 1000;
  const Completionist = () => {
    return (
      <>
        <img
          src={winnerUserLogo}
          alt="winnerlogo"
          className="winner-logo"
          onClick={audio}
        />
      </>
    );
  };

  const getApiData = async () => {
    const response = await fetch(
      "https://cryptolottery777.com/public/mega_jackpot_info"
    ).then((response) => response.json());
    setMegaJackpotData(response.data[0]?.amount);
  };
  const getApiCountData = async () => {
    const response = await fetch(
      "https://cryptolottery777.com/public/jackpot_tickets_count_info"
    ).then((response) => response.json());
    setMegaJackpotTicketsCount(response.data[0]);
  };
  const getApiCountDown = async () => {
    const response = await fetch(
      "https://cryptolottery777.com/public/countdown"
    ).then((response) => response.json());
    setCountdown(response.data);
  };

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
    if (completed) {
      // Render a complete state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <div className="datetime-container">
          <div className="datetime-content">
            <div className="datetime-number">{days}</div>
            <div className="datetime-text">{t("Дней")}</div>
          </div>
          <div className="datetime-content">
            <div className="datetime-number">{hours}</div>
            <div className="datetime-text">{t("Часов")}</div>
          </div>
          <div className="datetime-content">
            <div className="datetime-number">{minutes}</div>
            <div className="datetime-text">{t("Минуты")}</div>
          </div>
          <div className="datetime-content">
            <div className="datetime-number">{seconds}</div>
            <div className="datetime-text">{t("Секунды")}</div>
          </div>
        </div>
      );
    }
  };

  const handleBuyTicket = () => {
    navigate("/buyticket");
  };
  const handleBuyTicketMegaJackpot = () => {
    navigate("/buyticketmegajackpot");
  };

  let coll: any = document.getElementsByClassName("collapsible");
  const handleClickCollapse = () => {
    for (let i = 0; i < coll.length; i++) {
      coll[i].addEventListener("click", function () {
        coll[i].classList.toggle("active");
        let content = coll[i].nextElementSibling;
        if (content.style.maxHeight) {
          content.style.maxHeight = null;
        } else {
          content.style.maxHeight = content.scrollHeight + "px";
        }
      });
    }
  };
  return (
    <div className="auth">
      <div className="slider">
        <SlideItems />
      </div>

      {/*First block*/}
      <div className="info-content info-content-first-block">
        <div className="info-content-items">
          <div className="item-header">{t("Добро пожаловать")}</div>
          <div className="item-title">{t("уважаемые участники!")}</div>
          <div className="item-content">
            {t(
              "Присоединяйтесь к Crypto Lottery 777 уже сегодня, просто зарегистрировавшись на нашей платформе! Регистрация займет всего несколько минут, и Вы окажетесь на пороге захватывающего путешествия в мире криптолотерей.Не упустите свой шанс на победу! Приобретайте лотерейные билеты по доступной цене. Помните, что чем больше билетов у вас есть, тем выше ваши шансы на долгожданный выигрыш!"
            )}
          </div>
        </div>
        <div className="info-content-items">
          <TicketButton text="Билеты" />
          <div className="datetime-container date-container">
            <div className="datetime-content">
              <div className="datetime-number">
                {megaJackpotTicketsCount?.number_of_tickets_for_front}
              </div>
              <div className="datetime-text">{t("Всего билетов")}</div>
            </div>
            <div className="datetime-content">
              <div className="datetime-number">
                {megaJackpotTicketsCount?.number_of_available_tickets_for_front}
              </div>
              <div className="datetime-text">{t("Доступный")}</div>
            </div>
            <div className="datetime-content">
              <div className="datetime-number">
                {megaJackpotTicketsCount?.number_of_tickets_for_front -
                  megaJackpotTicketsCount?.number_of_available_tickets_for_front}
              </div>
              <div className="datetime-text">{t("Проданный")}</div>
            </div>
          </div>
          <BuyButton
            text="Купить билет"
            logo={buyBtnPrefix}
            func={handleBuyTicket}
          />
        </div>
      </div>
      {/*Second block*/}
      <div className="info-content">
        <div className="info-content-items">
          <div className="background-image">
            <img
              src={MegaJackpot}
              alt="avatar"
              className="mega-jackpot-image"
            />
          </div>
        </div>
        <div className="info-content-items">
          <div className="item-header">{t("Мега Джекпот")}</div>
          <div className="item-header">$ {megaJackpotData}</div>
          <div className="item-content">
            {t(
              "Каждые 30 дней проходит розыгрыш Мегаджекпота. В этот день, один счастливчик может выиграть огромный приз. Помимо этого, остальные призы также доступны для участников, создавая множество шансов на победу.Не упустите свой шанс на большой выигрыш! Присоединяйтесь к Crypto Lottery 777, участвуйте в наших лотереях и возможно именно вы станете обладателем Мегаджекпота, который изменит вашу жизнь к лучшему!"
            )}
          </div>
          {countdown && (
            <Countdown date={Date.now() + DAYS} renderer={renderer} />
          )}
        </div>
      </div>
      {/*Third block*/}
      <div className="info-content item-thired-block">
        <div className="info-content-items">
          <div className="item-header">{t("Реферальная программа")}</div>
          <div className="item-content item-content-thired-block">
            {t(
              "Приглашайте друзей и знакомых присоединиться к Crypto Lottery 777 и получайте дополнительный доход! Каждый участник, который приглашает нового игрока, получает 5% от суммы их покупки.  Если вы продали более 5 билетов, вы получаете 10% от суммы покупки каждого приглашенного игрока.Реферальные уровни: На первом уровне каждый участник получает 10% от выигрыша каждого приведенного другом участника. На втором уровне каждый участник получает 5% от выигрыша каждого участника, приглашенного его прямым рефералом. На третьем уровне каждый участник получает 2% от выигрыша каждого участника, приглашенного его прямым или косвенным рефералом на втором уровне."
            )}
          </div>
          <BuyButton
            text="Купить билет"
            logo={buyBtnPrefix}
            func={handleBuyTicket}
          />
        </div>
        <div className="info-content-items">
          <div className="background-image">
            <img src={AvatarImg} alt="avatar" className="rocket-image" />
          </div>
        </div>
      </div>
      {/*Fourth block*/}
      <div className="info-content">
        <div className="info-content-items fourth-block-1">
          <div className="background-image" style={{ marginBottom: "16px" }}>
            <img src={USDT2500} alt="avatar" className="usdt-image" />
          </div>
        </div>
        <div className="info-content-items fourth-block-2">
          <div className="item-header">{t("Джекпот")}</div>
          <div className="item-content item-content-fourth-block">
            {t(
              "Примите участие в захватывающем розыгрыше Джекпота!Мы постоянно проводим регулярные розыгрыши ценных призов. Как только наши участники приобретут 777 билетов, мы немедленно объявим победителей. В этот момент, один счастливчик станет обладателем  Джекпота!Не упустите свой шанс! Приобретайте билеты, участвуйте в наших розыгрышах и готовьтесь к возможности выиграть крупные призы.Джекпот составляет 2500 USDT!"
            )}
          </div>
          <BuyButton
            text="Купить билет"
            logo={buyBtnPrefix}
            func={handleBuyTicketMegaJackpot}
          />
        </div>
      </div>
      {/*Fifth block*/}
      <div className="info-content">
        <div className="info-content-items">
          <div className="last-item">
            <div className="left-side">
              <div className="title">{t("Правила")}</div>
              <img src={Assets1} alt="" />
            </div>
            <div className="right-side">
              <div className="right-side-text">
                {t(
                  "Присоединяйтесь к Crypto Lottery 777, зарегистрировавшись на нашей платформе. Это займет всего несколько минут, и вы будете готовы начать свое увлекательное путешествие по криптолотерее."
                )}
              </div>
              <div className="right-side-text-header">{t("Lorem ipsum")}</div>
              <div className="right-side-text">
                ETH, BTCB, WBNB, ADA, FIL, AVAX, DOGE, LTC, ATOM, SOL, SHIB,
                BIFI, BSW, DOT, BFG, FTM, XRP, LINK, UNI, XVS, TWT, MATIC
              </div>
              <div className="right-side-text">
                {t(
                  "Lorem ipsum dolor sit amet consectetur. Vitae metus sed sed laoreet tortor mollis lobortis viverra duis. Morbi diam non molestie sollicitudin nullam molestie scelerisque gravida. Gravida a consectetur platea hendrerit. Nunc tincidunt nisl turpis eu volutpat aliquet augue convallis. Vestibulum viverra viverra faucibus eleifend amet eu. Id erat feugiat augue sollicitudin pharetra aenean. Integer pretium malesuada quis non arcu risus. Aliquet tristique semper id augue vulputate iaculis vitae ornare laoreet. Quam euismod molestie viverra odio nunc. Rhoncus cursus at nec in. Sed nisl in habitant sagittis. Feugiat blandit vel dui neque."
                )}
              </div>
              <div className="right-side-text">
                {t(
                  "Lorem ipsum dolor sit amet consectetur. Vitae metus sed sed laoreet tortor mollis lobortis viverra duis. Morbi diam non molestie sollicitudin nullam molestie scelerisque gravida. Gravida a consectetur platea hendrerit. Nunc tincidunt nisl turpis eu volutpat aliquet augue convallis. Vestibulum viverra viverra faucibus eleifend amet eu. Id erat feugiat augue sollicitudin pharetra aenean. Integer pretium malesuada quis non arcu risus. Aliquet tristique semper id augue vulputate iaculis vitae ornare laoreet. Quam euismod molestie viverra odio nunc. Rhoncus cursus at nec in. Sed nisl in habitant sagittis. Feugiat blandit vel dui neque."
                )}
              </div>
              <div className="right-side-text">
                {t(
                  "Lorem ipsum dolor sit amet consectetur. Vitae metus sed sed laoreet tortor mollis lobortis viverra duis. Morbi diam non molestie sollicitudin nullam molestie scelerisque gravida. Gravida a consectetur platea hendrerit. Nunc tincidunt nisl turpis eu volutpat aliquet augue convallis. Vestibulum viverra viverra faucibus eleifend amet eu. Id erat feugiat augue sollicitudin pharetra aenean. Integer pretium malesuada quis non arcu risus. Aliquet tristique semper id augue vulputate iaculis vitae ornare laoreet. Quam euismod molestie viverra odio nunc. Rhoncus cursus at nec in. Sed nisl in habitant sagittis. Feugiat blandit vel dui neque."
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="info-content-items">
          <div className="last-item">
            <div className="left-side">
              <div className="title">{t("ЧАВО")}</div>
              <img src={Assets2} alt="" />
            </div>
            <div className="right-side">
              <button className="collapsible" onClick={handleClickCollapse}>
                {t("Lorem ipsum dolor sit amet consectetur?")}
              </button>
              <div className="content">
                <p>
                  {t(
                    "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempo."
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default AuthLayout;
