import TicketButton from "../buttons/TicketButton";
import React, { useEffect, useState } from "react";
import USDTLogo from "../assets/images/2500-usdt.svg";
import minus from "../assets/images/minus.svg";
import plus from "../assets/images/plus.svg";
import totalCountLogo from "../assets/images/totalcount-logo.svg";
import BuyButton from "../buttons/BuyButton";
import buyBtnPrefix from "../assets/images/buy-btn-prefix.svg";
import BuyingTicketHeader from "./BuyingTicketHeader";
import Swal from "sweetalert2";
import Spinner from "../buttons/Spinner";
import {
  buyTicket,
  getCycleCount,
  getSoldTicketCount,
  getTicketPrice,
  getUserInfo,
  getUserInfoForTicketsTable,
  metamaskConnection,
  mUSDTMint,
  tokenApprove,
} from "../scripts/web3";
import { log } from "util";
import Footer from "../auth/Footer";

function BuyTickets() {
  const [count, setCount] = useState(1);
  const [ticketsCountVerification, setTicketsCountVerification] =
    useState<any>();
  const [megaJackpotTicketsCount, setMegaJackpotTicketsCount] = useState<any>();
  const [inputTicketNumber, setInputTicketNumber] = useState<any>("");
  const [spin, setSpin] = useState<any>(true);

  const getApiCountData = async () => {
    const response = await fetch(
      "https://cryptolottery777.com/public/jackpot_tickets_count_info"
    ).then((response) => response.json());
    setMegaJackpotTicketsCount(response.data[0]);
  };

  useEffect(() => {
    getApiCountData();
  }, []);

  const getTicketsCountVerification = async () => {
    const tickets = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ticketsCount: count }),
    };
    fetch("https://cryptolottery777.com/public/left_tickets_count", tickets)
      .then((response) => response.json())
      .then((res) => {
        setTicketsCountVerification(res);
        if (!res.flag) {
          Swal.fire({
            title: "Ошибка!",
            text: `Максимальное количество доступных билетов ${res.remaining_tickets_count}`,
            icon: "error",
            confirmButtonText: "ok",
          });
        }
      });
  };

  const getPreferredTicketTable = async () => {
    let userData: any = await getUserInfoForTicketsTable();
    let wallet_address: any = await metamaskConnection();
    console.log("wal-addrs-pref", wallet_address);
    console.log("user-Data", userData);
    const tickets = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        wallet_address: wallet_address,
        inputTicketNumber: inputTicketNumber,
        ticketsArray: userData[0][3],
        cycleCount: userData[1],
      }),
    };
    fetch("https://cryptolottery777.com/public/tickets_data", tickets).then(
      (response) => response.json()
    );
  };
  const windowReload = () => {
    window.location.reload();
  };
  const getPreferredVerification = async () => {
    let cycleCount: any = await getCycleCount();

    const tickets = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        cycleCount: cycleCount,
        inputTicketNumber: inputTicketNumber,
      }),
    };
    fetch(
      "https://cryptolottery777.com/public/preferred_ticket_verification",
      tickets
    )
      .then((response) => response.json())
      .then((res) => {
        if (!res.flag) {
          Swal.fire({
            title: "Ошибка!",
            text: `Данный номер билета занят`,
            icon: "error",
            confirmButtonText: "ok",
          });
        }
      });
  };

  const handleBuyTicket = async () => {
    setSpin(false);
    getTicketsCountVerification();
    getPreferredVerification();
    const ticketPrice = await getTicketPrice();

    try {
      await tokenApprove((count * ticketPrice).toString()).then(async (res) => {
        if (res.status === true) {
          await buyTicket(count);
          await getSoldTicketCount();

          if (!inputTicketNumber) {
            await getUserInfo();
          } else {
            getPreferredTicketTable();
            await getUserInfo();
          }
        }
      });
    } catch (err: any) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Error: 4001",
        showConfirmButton: true,
      });
      setSpin(true);
      return;
    }
    setSpin(true);
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Success",
      showConfirmButton: true,
    }).then((res) => {
      if (res.isConfirmed) {
        console.log("res", res.isConfirmed);
        window.location.reload();
      }
    });
  };
  const handleIncrement = () => {
    setCount((prevCount) => {
      return (prevCount += 1);
    });
  };
  const handleDecrement = () => {
    setCount(function (prevCount) {
      if (prevCount > 0) {
        return (prevCount -= 1);
      } else {
        return (prevCount = 0);
      }
    });
  };

  return (
    <div style={{ height: "100%", overflowX: "hidden" }}>
    {spin ? (
      <div className="main" >
          <div className="buy-ticket-container">
            <BuyingTicketHeader
              logo={USDTLogo}
              headerTxt="Джекпот"
              contentTxt="Приобретайте билеты нашей лотереи по доступной цене в 25 USDT за билет. Чем больше билетов у вас есть, тем больше шансов на выигрыш вы имеете!
                        Участвуйте в розыгрыше: Мы проводим регулярные розыгрыши призов. Как только будет продано 777 билетов, мы объявим победителей! Джекпот составляет 2500 USDT!"
            />
            <div>
              <div className="buying-flow">
                <div className="buying-flow-left-side">
                  <div className="item-header">Джекпот</div>
                  <div className="item-content">
                    Приобретайте билеты нашей лотереи по доступной цене в 25
                    USDT за билет. Чем больше билетов у вас есть, тем больше
                    шансов на выигрыш вы имеете! Участвуйте в розыгрыше: Мы
                    проводим регулярные розыгрыши призов. Как только будет
                    продано 777 билетов, мы объявим победителей! Джекпот
                    составляет 2500 USDT!
                  </div>
                  <TicketButton text="Билеты" />
                  <div className="datetime-container date-container">
                    <div className="datetime-content">
                      <div className="datetime-number">
                        {megaJackpotTicketsCount?.number_of_tickets_for_front}
                      </div>
                      <div className="datetime-text">Всего билетов</div>
                    </div>
                    <div className="datetime-content">
                      <div className="datetime-number">
                        {
                          megaJackpotTicketsCount?.number_of_available_tickets_for_front
                        }
                      </div>
                      <div className="datetime-text">Доступный</div>
                    </div>
                    <div className="datetime-content">
                      <div className="datetime-number">
                        {megaJackpotTicketsCount?.number_of_tickets_for_front -
                          megaJackpotTicketsCount?.number_of_available_tickets_for_front}
                      </div>
                      <div className="datetime-text">Проданный</div>
                    </div>
                  </div>
                </div>
                <div className="buying-flow-right-side">
                  <div className="item-header">Купить билет</div>
                  <div className="increment-decrement">
                    <div className="first-row">
                      <div className="row-header">25 USDT за билет</div>
                      <div className="row-content">
                        {!inputTicketNumber ? (
                          <>
                            <img
                              src={minus}
                              alt="minus"
                              onClick={handleDecrement}
                            />
                            <span>{count}</span>
                            <img
                              src={plus}
                              alt="plus"
                              onClick={handleIncrement}
                            />
                          </>
                        ) : (
                          <>
                            <img src={minus} alt="minus" />
                            <span>1</span>
                            <img src={plus} alt="plus" />
                          </>
                        )}
                      </div>
                    </div>
                    <div className="second-row">
                      <div className="row-header">
                        Вы можете купить счастливый билет набрав номер внизу
                      </div>
                      <div className="row-content">
                        <input
                          type="text"
                          placeholder="Вставьте номер билета сюда"
                          onInput={(event: any) =>
                            setInputTicketNumber(event.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="total-count">
                      <img src={totalCountLogo} alt="totalCountLogo" />
                      <span>{25 * count}</span>
                      <span>USDT</span>
                    </div>
                  </div>
                  <div className="buy-ticket-div">
                    <BuyButton
                      text="Купить билет"
                      logo={buyBtnPrefix}
                      func={handleBuyTicket}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          ) : (
          <Spinner />
        )}
      <Footer />
    </div>
  );
}

export default BuyTickets;
