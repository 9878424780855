
import React, {useEffect, useState} from "react";
import MegaJackpot from "../assets/images/mega-jackpot.svg";
import minus from "../assets/images/minus.svg";
import plus from "../assets/images/plus.svg";
import totalCountLogo from "../assets/images/totalcount-logo.svg";
import BuyButton from "../buttons/BuyButton";
import buyBtnPrefix from "../assets/images/buy-btn-prefix.svg";
import BuyingTicketHeader from "./BuyingTicketHeader";
import Countdown from "react-countdown";
import winnerUserLogo from "../assets/images/winner.png";
import { useTranslation } from "react-i18next";
import Footer from "../auth/Footer";


function BuyMegaJackpotTicket() {
  const [count, setCount] = useState(1);
  const [countdown, setCountdown] = useState<any>();
  const { t } = useTranslation();

  const getApiCountDown = async () => {
    const response = await fetch(
        "https://cryptolottery777.com/public/countdown"
    ).then((response) => response.json());
    setCountdown(response.data);
  };
  useEffect(()=>{
    getApiCountDown()
  },[])

  const DAYS = (countdown?.day*24*60*60*1000) + (countdown?.hour*60*60*1000) + (countdown?.minute*60*1000) + (countdown?.second*1000);
  const audio=()=>{
    new Audio("https://codeskulptor-demos.commondatastorage.googleapis.com/pang/paza-moduless.mp3").play()
  }
  const Completionist = () => {
    return (
        <>
          <img src={winnerUserLogo} alt="winnerlogo" className="winner-logo" onClick={audio}/>
        </>
    );
  };

  const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
    if (completed) {
      return <Completionist />;
    } else {
      // Render a countdown
      return (
          <div className="datetime-container">
            <div className="datetime-content">
              <div className="datetime-number">{days}</div>
              <div className="datetime-text">{t("Дней")}</div>
            </div>
            <div className="datetime-content">
              <div className="datetime-number">{hours}</div>
              <div className="datetime-text">{t("Часов")}</div>
            </div>
            <div className="datetime-content">
              <div className="datetime-number">{minutes}</div>
              <div className="datetime-text">{t("Минуты")}</div>
            </div>
            <div className="datetime-content">
              <div className="datetime-number">{seconds}</div>
              <div className="datetime-text">{t("Секунды")}</div>
            </div>
          </div>
      );
    }
  };
  window. scrollTo({ top: 0, left: 0 })

  const handleIncrement = () => {
    setCount(function (prevCount) {
      return (prevCount += 1);
    });
  };
  const handleDecrement = () => {
    setCount(function (prevCount) {
      if (prevCount > 0) {
        return (prevCount -= 1);
      } else {
        return (prevCount = 0);
      }
    });
  };

  return (
      <div style={{height:"100%"}}>
    <div className="buy-ticket-container">
      <BuyingTicketHeader
        logo={MegaJackpot}
        headerTxt="Мега Джекпот"
        contentTxt="Внимание, внимание! Грядет самое ожидаемое событие года - Мега Джекпот Конкурс! У вас есть шанс стать обладателем огромного приза, который изменит вашу жизнь навсегда. Мега Джекпот Конкурс уже начался, и время идет!  Не упустите эту возможность изменить свою жизнь навсегда!"
      />
      <div className="buying-flow">
        <div className="buying-flow-left-side">
          <div className="item-header">Подробности о конкурсе</div>
          <div className="item-content">
            {t('Реферальная программа: Приглашайте друзей и знакомых присоединиться к Crypto Lottery 777 и получайте дополнительный доход! Каждый участник, который приглашает нового игрока, получает 5% от суммы их покупки. Кроме того, если вы продали не менее 5 билетов, вы получаете 10% от суммы покупки каждого приглашенного игрока.')}
          </div>
          <div className="header-text">До начало осталось</div>
            {countdown && (
                <Countdown date={Date.now() + DAYS} renderer={renderer} />
            )}
        </div>
        <div className="buying-flow-right-side">
          <div className="item-header">Купить билет</div>
          <div className="increment-decrement">
            <div className="first-row">
              <div className="row-header">25 USDT за билет</div>
              <div className="row-content">
                <img src={minus} alt="minus" onClick={handleDecrement} />
                <span>{count}</span>
                <img src={plus} alt="plus" onClick={handleIncrement} />
              </div>
            </div>
            <div className="second-row">
              <div className="row-header">Идентификатор реферала (ID)</div>
              <div className="row-content">
                <input
                  type="text"
                  placeholder="Вставьте идентификатор реферала сюда"
                />
              </div>
            </div>
            <div className="total-count">
              <img src={totalCountLogo} alt="totalCountLogo" />
              <span>{25 * count}</span>
              <span>USDT</span>
            </div>
          </div>
          <div className="buy-ticket-div">
            <BuyButton
              text="Купить билет"
              logo={buyBtnPrefix}
            />
          </div>
        </div>
      </div>
    </div>
        <Footer />
      </div>
  );
}

export default BuyMegaJackpotTicket;
