import React, { useEffect, useState } from "react";
import winnerLogo from "../assets/images/winner-logo.svg";
import {Pagination} from "antd";
import Ticket from "../components/Ticket";
import { useTranslation } from "react-i18next";
import {metamaskConnection} from "../scripts/web3";

function MyTicketsInProfile(props: any) {
  const [activeButton, setActiveButton] = useState("Все конкурсы");
  const [isAllCompetitionsOpen, setIsAllCompetitionsOpen] = useState(true);
  const [isWinningOpen, setIsWinningOpen] = useState(false);
  const [isCurrentOpen, setIsCurrentOpen] = useState(false);
  const [isFinishedOpen, setIsFinishedOpen] = useState(false);
  const [walletAddress, setWalletAddress] = useState<any>("");
  const [winnersData, setWinnersData] = useState<any>([]);
  const { t } = useTranslation();

  useEffect(() => {
    metamaskConnection().then(res => setWalletAddress(res))
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        wallet_address: walletAddress,
      }),
    };
    fetch(
      "https://cryptolottery777.com/public/winning_tickets_info",
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        setWinnersData(response.data);
      });
  }, [walletAddress]);

  const handleChangeStyle = (buttonName: any) => {
    setActiveButton(buttonName);
    switch (buttonName) {
      case "Все конкурсы":
        setIsAllCompetitionsOpen(true);
        setIsCurrentOpen(false);
        setIsFinishedOpen(false);
        setIsWinningOpen(false);
        break;
      case "Выигранный":
        setIsWinningOpen(true);
        setIsAllCompetitionsOpen(false);
        setIsCurrentOpen(false);
        setIsFinishedOpen(false);
        break;
      case "Текущий":
        setIsCurrentOpen(true);
        setIsWinningOpen(false);
        setIsAllCompetitionsOpen(false);
        setIsFinishedOpen(false);
        break;
      case "Оконченный":
        setIsFinishedOpen(true);
        setIsCurrentOpen(false);
        setIsAllCompetitionsOpen(false);
        setIsWinningOpen(false);
        break;
    }
  };
  const navData = [
    { name: "Все конкурсы" },
  ];

  const handleChangePage = (page:any,pageSize:any) => {
    console.log("page",page)
    console.log("pageSize",pageSize)
  }

  return (
    <>
      <div className="concurse-main">
        <div className="header-left-text">Мои билеты</div>
        <div className="header-right-nav">
          {navData.map((val: any, key: any) => {
            return (
              <div
                onClick={() => handleChangeStyle(val.name)}
                className={activeButton === val.name ? "color-change" : ""}
                key={key}
              >
                <span className="nav-item">{val.name}</span>
              </div>
            );
          })}
        </div>
      </div>
      <div className="all-concurses">
        {isAllCompetitionsOpen
          ? props.responseData?.map((el: any,index:any) => (
              <div className="competition" key={index}>
                <Ticket
                    isView={true}
                    index={index}
                    lastItem={props.lastItem}
                    responseData={props.responseData}
                    contentHeader={t("Джекпот")}
                    contentText={t(
                        "После успешной продажи 777 билетов, наступает момент истинной радости - розыгрыш призов в Crypto Lottery 777. Мы готовы поделиться счастьем с нашими участниками и вручить ценные призы!"
                    )}
                    buttonText={el}
                    winnerLogo={winnerLogo}
                />
              </div>
            ))
          : ""}

      </div>
    </>
  );
}

export default MyTicketsInProfile;
