import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, HashRouter } from "react-router-dom";
import "./translation/i18n";
import { MetaMaskProvider } from "@metamask/sdk-react";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <HashRouter>
      <MetaMaskProvider
        debug={true}
        sdkOptions={{
          logging: {
            developerMode: false,
          },
          checkInstallationImmediately: true, // This will automatically connect to MetaMask on page load
          dappMetadata: {
            name: "",
            url: "",
          },
        }}
      >
        <App />
      </MetaMaskProvider>
    </HashRouter>
  </React.StrictMode>
);
reportWebVitals();
