import React, {useEffect, useState} from "react";
import CopyImg from "../assets/images/copy.svg";
import totalCountLogo from "../assets/images/totalcount-logo.svg";
import CloseIcon from "../assets/images/close.svg";
import ProfileTable from "./ProfileTable";
import MyTicketsInProfile from "./MyTicketsInProfile";
import {metamaskConnection} from "../scripts/web3";


import Web3 from 'web3';
import Footer from "../auth/Footer";

function Profile() {
    const [selectedImage, setSelectedImage] = useState<any>();
    const [isClose, setIsClose] = useState(true);
    const [email, setEmail] = useState("");
    const [isEmail, setisEmail] = useState("");
    const [walletAddress, setWalletAddress] = useState<any>("");
    const [tableData, setTableData] = useState<any>([]);
    const [bonusesData, setBonusesData] = useState<any>([]);
    const [responseData, setResponseData] = useState([]);
    const [lastItem, setLastItem] = useState<any>([]);
    const [web3Instance, setWeb3] = useState<any>(null)


    useEffect(() => {
        metamaskConnection().then((res) => {
            if (res) {
                setWalletAddress(res);
                const avatarOption = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        wallet_address: res,
                    }),
                };
                fetch("https://cryptolottery777.com/public/avatar_data", avatarOption)
                    .then((response) => response.blob())
                    .then((blob) => {
                        const imageURL = URL.createObjectURL(blob);
                        setSelectedImage(imageURL);

                    })
                    .catch((error) => {
                        console.error("Error:", error);
                    });
                const requestOptions = {
                    method: "POST",
                    headers: {"Content-Type": "application/json"},
                    body: JSON.stringify({wallet_address: res}),
                };
                fetch("https://cryptolottery777.com/public/user_data", requestOptions)
                    .then((response) => response.json())
                    .then((response) => {
                        setTableData(response.data);
                    });
                fetch("https://cryptolottery777.com/public/bonuses_info", requestOptions)
                    .then((response) => response.json())
                    .then((response) => {
                        setBonusesData(response.data);
                    });
            }
        });
    }, []);

    useEffect(() => {
        let array: any = tableData.map((el: any) => el.tickets_arr)
        let lastItemOfArray = array[array.length - 1]
        setResponseData(array.flat())
        setLastItem(lastItemOfArray)
    }, [tableData])

    const handleImageUpload = (event: any) => {
        let address: any;
        metamaskConnection().then((res) => {
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append("avatar", file);
            formData.append(
                "wallet_address",
                res
            );
            const requestOptions: any = {
                method: "POST",
                body: formData,
            };

            fetch("https://cryptolottery777.com/public/avatar", requestOptions)
                .then((response) => response.blob())
                .then((blob) => {
                    // Assuming you want to display the Blob as an image

                    const imageURL = URL.createObjectURL(blob);
                    window.location.reload();
                })
        })

            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const copyToClipboardAddress = async () => {
        if ("clipboard" in navigator) {
            if (walletAddress) await navigator.clipboard.writeText(walletAddress);
        }
    };
    const copyToClipboardId = async () => {
        if ("clipboard" in navigator) {
            await navigator.clipboard.writeText(tableData[0]?.referral_id_for_front);
        }
    };
    const handleCloseContainer = () => {
        setIsClose(false);
    };
    const handleSendEmail = async () => {
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({wallet_address: walletAddress, email: email}),
        };
        fetch("https://cryptolottery777.com/public/email", requestOptions)
            .then((response) => response.json())
            .then((response) => {
            });
        setIsClose(false);
    };

    return (
        <div style={{height: "100%"}}>
            <div className="main">
                {isClose && !tableData[0]?.email ? (
                    <div className="input-email-container">
                        <div className="first-content">
                            <div className="first-content-title">Оставайтесь на связи</div>
                            <div className="first-content-description">
                                Подпишитесь и будьте в курсе последних новостей и специальных
                                предложений
                            </div>
                        </div>
                        <div className="input-container">
                            <input
                                type="email"
                                placeholder="Email Address"
                                onChange={(event) => setEmail(event.target.value)}
                            />
                            <button onClick={handleSendEmail}>connect</button>
                            <img
                                src={CloseIcon}
                                alt="close"
                                onClick={handleCloseContainer}
                                style={{cursor: "pointer"}}
                            />
                        </div>

                    </div>
                ) : (
                    ""
                )}
                <div className="profile-first-row">
                    <div className="details">
                        <div className="details-header">
                            <span>Детали</span>
                        </div>
                        <div className="file-input">
                            <div className="image-group">
                                <img src={selectedImage} alt="" className="file-input-image"/>
                            </div>
                            <label htmlFor="file" className="file-input-lable">
                                Add Photo
                            </label>
                            <input
                                type="file"
                                id="file"
                                onChange={handleImageUpload}
                                style={{display: "none"}}
                            />
                        </div>
                        <div className="details-input_copy">
                            <div className="details-input_copy-header">Wallet address</div>
                            <div className="copy">
              <span>
                {walletAddress.slice(0, 8) + "..." + walletAddress.slice(-8)}
              </span>
                                <img src={CopyImg} alt="copy" onClick={copyToClipboardAddress}/>
                            </div>
                        </div>
                        <div className="details-input_copy">
                            <div className="details-input_copy-header">My Referral ID</div>
                            <div className="copy">
                                <span>{tableData[0]?.referral_id_for_front}</span>
                                <img src={CopyImg} alt="copy" onClick={copyToClipboardId}/>
                            </div>
                        </div>
                    </div>

                    <div className="profile-second-row">
                        <ProfileTable tableData={bonusesData}/>
                    </div>

                    <div className="details">
                        <div className="details-header">
                            <span>Финансы</span>
                        </div>
                        <div className="wallet-header">Баланс</div>
                        <div className="wallet-content-main">
                            <div className="wallet-content">
                                <img src={totalCountLogo} alt=""/>
                                <div>
                                    {Number(tableData[0]?.balance_of) / Math.pow(10, 18)} USDT
                                </div>
                            </div>
                        </div>
                        <div className="wallet-header">Статистика</div>
                        <div className="wallet-content-main">
                            <div className="statistic">
                                <div className="referal-count-title">Количество рефералов</div>
                                <div className="referal-count">
                                    {tableData[0]?.referrals_count}
                                </div>
                            </div>
                            <div className="statistic">
                                <div className="referal-count-title">Продано билетов</div>
                                <div className="referal-count">
                                    {tableData[0]?.sold_tickets_count}
                                </div>
                            </div>
                            <div className="statistic">
                                <div className="referal-count-title">Купленные билеты</div>
                                <div className="referal-count">
                                    {tableData[0]?.tickets_arr.length}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="profile-thired-row">
                    <MyTicketsInProfile responseData={responseData} lastItem={lastItem}/>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Profile;
