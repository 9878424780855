/*
pages
*/
import AuthLayout from "../auth/AuthLayout";
import BuyTickets from "../components/BuyTickets";
import BuyMegaJackpotTicket from "../components/BuyMegaJackpotTicket";
import Profile from "../profile/Profile";
import PageNotFound from "../components/PageNotFound";
import MegaJackpotContent from "../contests/MegaJackpotContent";
import JackpotContent from "../contests/JackpotContent";
import Bonuse from "../contests/Bonuse";
import Statistic from "../contests/Statistic";


interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const publicRoutes: Array<RouteProps> = [
  { path: "/", component: AuthLayout },
  { path: "/buyticket", component: BuyTickets },
  { path: "/buyticketmegajackpot", component:BuyMegaJackpotTicket },
  { path: "/profile", component: Profile },
  { path: "/contests", component: Bonuse },
  { path: "/statistic", component: Statistic },
  { path: "/megaJackpotContent", component: MegaJackpotContent },
  { path: "/jackpotContent", component: JackpotContent },
  { path: "*", component: PageNotFound },
];


export {
  publicRoutes,
};
