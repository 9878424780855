import React from "react";
import telegramLogo from "../assets/images/Telegram.svg";
import instagramLogo from "../assets/images/Instagram.svg";
import facebookLogo from "../assets/images/Facebook.svg";
import twitterLogo from "../assets/images/Twitter.svg";
import linkedinLogo from "../assets/images/LinkedIn-logo.svg";
import youtubeLogo from "../assets/images/YouTube.svg";
import mediumLogo from "../assets/images/Medium.svg";
import discordLogo from "../assets/images/Discord.svg";

function Footer () {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-content-header">
                    Найдите нас в социальных сетях
                </div>
                <div className="social-items-group">
                    <a href="https://web.telegram.org" target="_blank"><img src={telegramLogo} alt=""/></a>
                    <a href="https://www.instagram.com" target="_blank"><img src={instagramLogo} alt=""/></a>
                    <a href="https://www.facebook.com" target="_blank"><img src={facebookLogo} alt=""/></a>
                    <a href="https://twitter.com" target="_blank"><img src={twitterLogo} alt=""/></a>
                    <a href="https://www.linkedin.com" target="_blank"><img src={linkedinLogo} alt=""/></a>
                    <a href="https://www.youtube.com" target="_blank"><img src={youtubeLogo} alt=""/></a>
                    <a href="https://medium.com" target="_blank"><img src={mediumLogo} alt=""/></a>
                    <a href="https://discord.com" target="_blank"><img src={discordLogo} alt=""/></a>
                </div>
            </div>
        </footer>
    )
}

export default Footer
