import React, {useState} from 'react';
import './App.css';
import Header from "./auth/Header";
import Footer from "./auth/Footer";
import IndexRoute from "./routes/IndexRoute";
import Spinner from "./buttons/Spinner";



function App() {
    const [loader, setLoader] = useState(false);

    return (
        <>
            {!loader? <div className="App">
                <Header
                    loader={loader}
                    setLoader={setLoader}
                />
                <IndexRoute />
            </div>:  <Spinner/>}
        </>
    );
}

export default App;

