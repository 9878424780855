import Recourse1 from "../assets/images/Resource-1.png"
import Recourse2 from "../assets/images/Resource-2.png"
import Recourse3 from "../assets/images/Resource-3.png"
import Recourse4 from "../assets/images/Resource-4.png"

export const MegaJackpotRecourseData = [
    {
        image:Recourse1,
        content: "Растущий Выигрыш: В течение 30 дней мы аккумулируем средства на счете Мега Джекпота."
    },
    {
        image:Recourse2,
        content: "Накопление : С каждого розыгрыша 3% от общей суммы взносов отправляются на специальный счет Мега Джекпота."
    },
    {
        image:Recourse3,
        content: "Грандиозный Розыгрыш: По истечении 30 дней наступает самое ожидаемое мгновение - Грандиозный Розыгрыш Мега Джекпота!"
    },
    {
        image:Recourse4,
        content: "Шансы для всех: Каждый участник, приобретший билеты на Розыгрыш Мега Джекпота, автоматически становится участником розыгрыша и имеет шанс 1 к 777 выиграть Мега Джекпот."
    },
]
export const BonuseData = [
    {
        image:Recourse1,
        content: "Бонус за привлечение рефералов: Игрок получит дополнительный бонус в размере 5 USDT, за  привлечение 10 рефералов."
    },
    {
        image:Recourse2,
        content: "Бонус за продажу билетов: Игрок получит дополнительный бонус в размере 100 USDT, за продажу 50 билетов."
    },
    {
        image:Recourse3,
        content: "Бонус за победу в лотерее: Игрок получит дополнительный бонус в размере 50 USDT, при выигрыше в 3 розыгрышах подряд"
    },
    {
        image:Recourse4,
        content: "Бонус за участие в лотерее: Игрок может получить дополнительный бонус в размере 5 USDT ,если купит 10 билетов"
    },
]

export const JackpotRecourseData = [
    {
        image:Recourse1,
        content: "Сумма Джекпота: 2,500 USDT"
    },
    {
        image:Recourse2,
        content: "Частота Розыгрышей Джекпота: Джекпот разыгрывается после продажи каждых 777 билетов."
    },
    {
        image:Recourse3,
        content: "Как Выиграть Джекпот: Для выигрыша Джекпота, участнику необходимо купить билет и его номер должен совпасть с определенным выигрышными номером, который выбирается случайным образом при каждом розыгрыше."
    },
    {
        image:Recourse4,
        content: "Следите за Джекпотом: Информацию о текущей сумме Джекпота можно узнать на нашем сайте."
    },
]
