import React, {useEffect, useState} from "react";
import MegaJackpot from "../assets/images/mega-jackpot.svg";
import {useNavigate} from "react-router-dom";
import Countdown from "react-countdown";
import winnerUserLogo from "../assets/images/winner.png";
import {useTranslation} from "react-i18next";
import MegaJackpotInfo from "./MegaJackpotInfo";
import {MegaJackpotRecourseData} from "../assets/images";
import Footer from "../auth/Footer";

function MegaJackpotContent() {
    const navigate = useNavigate();
    const [countdown, setCountdown] = useState<any>();
    const {t} = useTranslation();

    const getApiCountDown = async () => {
        const response = await fetch(
            "https://cryptolottery777.com/public/countdown"
        ).then((response) => response.json());
        setCountdown(response.data);
    };
    useEffect(() => {
        getApiCountDown()
    })

    const DAYS =
        countdown?.day * 24 * 60 * 60 * 1000 +
        countdown?.hour * 60 * 60 * 1000 +
        countdown?.minute * 60 * 1000 +
        countdown?.second * 1000;
    // const DAYS = 5000;
    const Completionist = () => {
        // const [play, { stop }] = useSound(fanfan);
        return (
            <>
                <img
                    src={winnerUserLogo}
                    alt="winnerlogo"
                    className="winner-logo"
                />
            </>
        );
    };
    const renderer = ({days, hours, minutes, seconds, completed}: any) => {
        if (completed) {
            // Render a complete state
            return <Completionist/>;
        } else {
            // Render a countdown
            return (
                <div className="datetime-container">
                    <div className="datetime-content">
                        <div className="datetime-number">{days}</div>
                        <div className="datetime-text">{t("Дней")}</div>
                    </div>
                    <div className="datetime-content">
                        <div className="datetime-number">{hours}</div>
                        <div className="datetime-text">{t("Часов")}</div>
                    </div>
                    <div className="datetime-content">
                        <div className="datetime-number">{minutes}</div>
                        <div className="datetime-text">{t("Минуты")}</div>
                    </div>
                    <div className="datetime-content">
                        <div className="datetime-number">{seconds}</div>
                        <div className="datetime-text">{t("Секунды")}</div>
                    </div>
                </div>
            );
        }
    };
    return (
        <div style={{height:"100%"}}>
            <div className="main-mega-jackpot">
                {/*First Content*/}
                <div className="concurse-main">
                    <div className="info-content" style={{padding: "0 0"}}>
                        <div className="info-content-items">
                            <div className="background-image-contents">
                                {/*<img src={backgroundImg} alt=""/>*/}
                                <img src={MegaJackpot} alt="avatar" className="jackpot-img"/>
                            </div>
                        </div>
                        <div className="info-content-items">
                            <div className="item-header">Мега Джекпот</div>
                            <div className="item-content">
                                {t("Мега джекпот (раздел)")}
                            </div>
                            {countdown && (
                                <Countdown date={Date.now() + DAYS} renderer={renderer}/>
                            )}
                        </div>
                    </div>
                </div>
                {/*Second Content*/}
                <div className="second-content-main">
                    {MegaJackpotRecourseData.map((item:any)=>
                        <MegaJackpotInfo
                            image={item.image}
                            text= {item.content}
                        />
                    )}
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default MegaJackpotContent;
