import React from "react";
import {useTranslation} from "react-i18next";

const BuyButton = (props:any) => {
const { t } = useTranslation()
return (
<button className="buy-btn" onClick={props.func}>
        <img src={props.logo} alt=""/>
        <span>{t(props.text)}</span>
    </button>
)
};

export default BuyButton;
