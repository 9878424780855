import React from "react";
function PageNotFound()  {
    return (
        <>
        <div style={{height:"100%",width: "100%", display:"flex", alignItems:"center",justifyContent:"center",background:"red"}}>
            Page Not Found
        </div>
        </>
    )
}

export default PageNotFound;
