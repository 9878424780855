import React from "react";

const ConnectButton = (props:any) => (
    <button className="connect-btn" onClick={props.func}>
        <img src={props.logo} alt=""/>
        <span>{props.text}</span>
    </button>
);

export default ConnectButton;
