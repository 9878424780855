export const resources = {
    ru: {
        translation: {
            "Главная страница": "Главная страница",
            "Конкурсы":"Конкурсы",
            "Профиль":"Профиль",
            "Добро пожаловать": "Добро пожаловать",
            "Подключить кошелек": "Подключить кошелек",
            "уважаемые участники!": "уважаемые участники!",
            "Присоединяйтесь к Crypto Lottery 777 уже сегодня, просто зарегистрировавшись на нашей платформе! Регистрация займет всего несколько минут, и Вы окажетесь на пороге захватывающего путешествия в мире криптолотерей.Не упустите свой шанс на победу! Приобретайте лотерейные билеты по доступной цене. Помните, что чем больше билетов у вас есть, тем выше ваши шансы на долгожданный выигрыш!": "Присоединяйтесь к Crypto Lottery 777 уже сегодня, просто зарегистрировавшись на нашей платформе! Регистрация займет всего несколько минут, и Вы окажетесь на пороге захватывающего путешествия в мире криптолотерей.Не упустите свой шанс на победу! Приобретайте лотерейные билеты по доступной цене. Помните, что чем больше билетов у вас есть, тем выше ваши шансы на долгожданный выигрыш!",
            "Извините, ваша транзакция не удалась. Пожалуйста, внимательно проверьте введенные данные и повторите попытку.":"Извините, ваша транзакция не удалась. Пожалуйста, внимательно проверьте введенные данные и повторите попытку.",
            "Ошибка":"Ошибка",
            "Название конкурса":"Название конкурса",
            "Адрес кошелька":"Адрес кошелька",
            "Купленные билеты":"Купленные билеты",
            "Бонус":"Бонус",
            "Бонусы":"Бонусы",
            "Мега Джекпот":"Мега Джекпот",
            "Каждые 30 дней проходит розыгрыш Мегаджекпота. В этот день, один счастливчик может выиграть огромный приз. Помимо этого, остальные призы также доступны для участников, создавая множество шансов на победу.Не упустите свой шанс на большой выигрыш! Присоединяйтесь к Crypto Lottery 777, участвуйте в наших лотереях и возможно именно вы станете обладателем Мегаджекпота, который изменит вашу жизнь к лучшему!":"Каждые 30 дней проходит розыгрыш Мегаджекпота. В этот день, один счастливчик может выиграть огромный приз. Помимо этого, остальные призы также доступны для участников, создавая множество шансов на победу.Не упустите свой шанс на большой выигрыш! Присоединяйтесь к Crypto Lottery 777, участвуйте в наших лотереях и возможно именно вы станете обладателем Мегаджекпота, который изменит вашу жизнь к лучшему!",
            "Купить билет":"Купить билет",
            "Джекпот":"Джекпот",
            "Дней":"Дней",
            "Часов":"Часов",
            "Минуты":"Минуты",
            "Секунды":"Секунды",
            "Примите участие в захватывающем розыгрыше Джекпота!Мы постоянно проводим регулярные розыгрыши ценных призов. Как только наши участники приобретут 777 билетов, мы немедленно объявим победителей. В этот момент, один счастливчик станет обладателем  Джекпота!Не упустите свой шанс! Приобретайте билеты, участвуйте в наших розыгрышах и готовьтесь к возможности выиграть крупные призы.Джекпот составляет 2500 USDT!":"Примите участие в захватывающем розыгрыше Джекпота!Мы постоянно проводим регулярные розыгрыши ценных призов. Как только наши участники приобретут 777 билетов, мы немедленно объявим победителей. В этот момент, один счастливчик станет обладателем  Джекпота!Не упустите свой шанс! Приобретайте билеты, участвуйте в наших розыгрышах и готовьтесь к возможности выиграть крупные призы.Джекпот составляет 2500 USDT!",
            "Билеты":"Билеты",
            "Всего билетов":"Всего билетов",
            "Доступный":"Доступный",
            "Правила":"Правила",
            "Присоединяйтесь к Crypto Lottery 777, зарегистрировавшись на нашей платформе. Это займет всего несколько минут, и вы будете готовы начать свое увлекательное путешествие по криптолотерее.":"Присоединяйтесь к Crypto Lottery 777, зарегистрировавшись на нашей платформе. Это займет всего несколько минут, и вы будете готовы начать свое увлекательное путешествие по криптолотерее.",
            "ЧАВО":"ЧАВО",
            "Текущий":"Текущий",
            "Реферальная программа: Приглашайте друзей и знакомых присоединиться к Crypto Lottery 777 и получайте дополнительный доход! Каждый участник, который приглашает нового игрока, получает 5% от суммы их покупки. Кроме того, если вы продали не менее 5 билетов, вы получаете 10% от суммы покупки каждого приглашенного игрока.":"Реферальная программа: Приглашайте друзей и знакомых присоединиться к Crypto Lottery 777 и получайте дополнительный доход! Каждый участник, который приглашает нового игрока, получает 5% от суммы их покупки. Кроме того, если вы продали не менее 5 билетов, вы получаете 10% от суммы покупки каждого приглашенного игрока.",
            "После успешной продажи 777 билетов, наступает момент истинной радости - розыгрыш призов в Crypto Lottery 777. Мы готовы поделиться счастьем с нашими участниками и вручить ценные призы!":"После успешной продажи 777 билетов, наступает момент истинной радости - розыгрыш призов в Crypto Lottery 777. Мы готовы поделиться счастьем с нашими участниками и вручить ценные призы!",
            "Результаты":"Результаты",
            "Бонусы(раздел)": "Бонусы в лотерее 'Crypto Lottery 777' Наши бонусы представляют собой специальные поощрения и привилегии, которые мы предоставляем нашим участникам для обогащения их опыта в нашей криптолотерее. Мы стремимся создать увлекательное и выгодное игровое окружение, и бонусы - один из способов сделать это.",
            "Бонуси":"Бонусы",
            "Статистика": "Статистика",
            "Джекпот(раздел)": "Присоединяйтесь к нам и выигрывайте! В нашей лотерее вы можете приобрести билеты по доступной цене всего 25 USDT за билет. И помните, чем больше билетов у вас есть, тем больше шансов на выигрыш! Мы проводим регулярные розыгрыши призов для наших участников. И как только будет продано 777 билетов, мы объявим победителей! Не упустите шанс выиграть Джекпот в размере 2500 USDT!",
            "Мега джекпот (раздел)": "Мега Джекпот в лотерее 'Crypto Lottery 777' - это уникальная возможность выиграть огромный приз. Это грандиозное событие, которое происходит каждые 30 дней и предоставляет игрокам шанс стать миллионером. С каждым розыгрышем Мега Джекпот растет и растет, накапливая внушительную сумму. Как только наступает момент Мега Джекпота, один счастливчик получает шанс выиграть это богатство.Чем больше билетов вы покупаете, тем больше шансов у вас выиграть Мега Джекпот. Мега Джекпот в лотерее 'Crypto Lottery 777' - это не просто приз, это история о том, как удача может улыбнуться каждому, кто решит попробовать свои силы.",
            "Мы гордимся тем, что 'Crypto Lottery 777' предоставляет выдающиеся возможности для выигрышей и незабываемых моментов для наших участников. Ниже вы найдете ключевую статистику, которая демонстрирует нашу прозрачность и доверие, которым мы обладаем.":"Мы гордимся тем, что 'Crypto Lottery 777' предоставляет выдающиеся возможности для выигрышей и незабываемых моментов для наших участников. Ниже вы найдете ключевую статистику, которая демонстрирует нашу прозрачность и доверие, которым мы обладаем.",

        }
    },
    en: {
        translation: {
            "Главная страница": "Main Page",
            "Конкурсы":"Contests",
            "Профиль":"Profile",
            "Добро пожаловать": "Welcome",
            "Подключить кошелек": "Connect wallet",
            "уважаемые участники!": "dear participants!",
            "Присоединяйтесь к Crypto Lottery 777 уже сегодня, просто зарегистрировавшись на нашей платформе! Регистрация займет всего несколько минут, и Вы окажетесь на пороге захватывающего путешествия в мире криптолотерей.Не упустите свой шанс на победу! Приобретайте лотерейные билеты по доступной цене. Помните, что чем больше билетов у вас есть, тем выше ваши шансы на долгожданный выигрыш!":"We are pleased to present you the exciting and profitable Crypto Lottery 777! This is your unique opportunity not only to experience the excitement and win big prizes, but also to earn on our referral program. Each member who invites a new player receives 5% of their purchase amount. In addition, if you have sold at least 5 tickets, you will receive 10% of the purchase amount of each invited player.",
            "Извините, ваша транзакция не удалась. Пожалуйста, внимательно проверьте введенные данные и повторите попытку.":"Sorry, your transaction failed. Please check the input and input details carefully.",
            "Ошибка":"Error",
            "Название конкурса":"Competition name",
            "Адрес кошелька":"Wallet address",
            "Купленные билеты":"Purchased tickets",
            "Бонус":"Bonus",
            "Бонусы":"Bonuses",
            "Мега Джекпот":"Mega Jackpot",
            "Каждые 30 дней проходит розыгрыш Мегаджекпота. В этот день, один счастливчик может выиграть огромный приз. Помимо этого, остальные призы также доступны для участников, создавая множество шансов на победу.Не упустите свой шанс на большой выигрыш! Присоединяйтесь к Crypto Lottery 777, участвуйте в наших лотереях и возможно именно вы станете обладателем Мегаджекпота, который изменит вашу жизнь к лучшему!":"A Mega Jackpot draw takes place every 30 days. On this day, one lucky person can win a huge prize. In addition, other prizes are also available to participants, creating many chances to win.Don't miss your chance to win big! Join Crypto Lottery 777, participate in our lotteries and maybe you will become the owner of a Mega Jackpot that will change your life for the better!",
            "Купить билет":"Buy ticket",
            "Джекпот":"Jackpot",
            "Дней":"Days",
            "Часов":"Hours",
            "Минуты":"Minutes",
            "Секунды":"Seconds",
            "Примите участие в захватывающем розыгрыше Джекпота!Мы постоянно проводим регулярные розыгрыши ценных призов. Как только наши участники приобретут 777 билетов, мы немедленно объявим победителей. В этот момент, один счастливчик станет обладателем  Джекпота!Не упустите свой шанс! Приобретайте билеты, участвуйте в наших розыгрышах и готовьтесь к возможности выиграть крупные призы.Джекпот составляет 2500 USDT!": "Take part in an exciting Jackpot drawing!We constantly hold regular sweepstakes of valuable prizes. As soon as our participants purchase 777 tickets, we will immediately announce the winners. At this moment, one lucky person will become the owner of the Jackpot!Don't miss your chance! Buy tickets, participate in our sweepstakes and get ready for the opportunity to win big prizes.The jackpot is 2500 USDT!",
            "Билеты":"Tickets",
            "Всего билетов":"Total tickets",
            "Доступный":"Accessible",
            "Правила":"Rules",
            "Присоединяйтесь к Crypto Lottery 777, зарегистрировавшись на нашей платформе. Это займет всего несколько минут, и вы будете готовы начать свое увлекательное путешествие по криптолотерее.":"Join Crypto Lottery 777 by registering on our platform. It will only take a few minutes and you will be ready to start your exciting crypto lottery journey.",
            "ЧАВО":"FAQ",
            "Текущий":"Current",
            "Реферальная программа: Приглашайте друзей и знакомых присоединиться к Crypto Lottery 777 и получайте дополнительный доход! Каждый участник, который приглашает нового игрока, получает 5% от суммы их покупки. Кроме того, если вы продали не менее 5 билетов, вы получаете 10% от суммы покупки каждого приглашенного игрока.":"Referral program: invite your friends and acquaintances to join Crypto Lottery 777 and get extra income! Each member who invites a new player receives 5% of the purchase amount. In addition, if you have sold at least 5 tickets, you will receive 10% of the purchase amount of each invited player.",
            "После успешной продажи 777 билетов, наступает момент истинной радости - розыгрыш призов в Crypto Lottery 777. Мы готовы поделиться счастьем с нашими участниками и вручить ценные призы!":"After the successful sale of 777 tickets, the moment of true joy comes - the drawing of prizes in Crypto Lottery 777. We are ready to share happiness with our participants and present valuable prizes!",
            "Результаты":"Results",
            "Бонуси":"Bonuses",
            "Статистика": "Statistics",
            "Бонусы(раздел)": "Crypto Lottery 777 Bonuses Our bonuses are special incentives and privileges that we provide to our members to enrich their experience in our crypto lottery. We strive to create a fun and rewarding gaming environment, and bonuses are one way to do this.",
            "Джекпот(раздел)": "Join us and win! In our lottery you can purchase tickets at an affordable price of only 25 USDT per ticket. And remember, the more tickets you have, the greater your chances of winning! We hold regular prize draws for our members. And as soon as 777 tickets are sold, we will announce the winners! Don't miss your chance to win the 2500 USDT Jackpot!"   ,
            "Мега джекпот(раздел)": "With each draw, the Mega Jackpot grows and grows, accumulating an impressive amount. Once the Mega Jackpot moment arrives, one lucky person gets the chance to win this riches. The more tickets you buy, the more chances you have of winning the Mega Jackpot. The Mega Jackpot in the Crypto Lottery 777 lottery is not just a prize, it is a story about how luck can smile on everyone who decides to try their hand.",
            "Мы гордимся тем, что 'Crypto Lottery 777' предоставляет выдающиеся возможности для выигрышей и незабываемых моментов для наших участников. Ниже вы найдете ключевую статистику, которая демонстрирует нашу прозрачность и доверие, которым мы обладаем.":"Мы гордимся тем, что 'Crypto Lottery 777' предоставляет выдающиеся возможности для выигрышей и незабываемых моментов для наших участников. Ниже вы найдете ключевую статистику, которая демонстрирует нашу прозрачность и доверие, которым мы обладаем."
          }
        },
}
