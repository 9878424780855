import React from "react";
import {useTranslation} from "react-i18next";

const TicketButton = (props:any) => {
    const { t } = useTranslation()
    return (
    <button className="ticket-btn" onClick={props.func}>
        <span>{t(props.text)}</span>
    </button>
    )
};

export default TicketButton;
