import React from "react";
import Logo2500 from "../assets/images/2500-usdt.svg";
import buyBtnPrefix from "../assets/images/buy-btn-prefix.svg";
import BuyButton from "../buttons/BuyButton";
import { useNavigate } from "react-router-dom";
import { JackpotRecourseData, MegaJackpotRecourseData } from "../assets/images";
import MegaJackpotInfo from "./MegaJackpotInfo";
import { useTranslation } from "react-i18next";
import Footer from "../auth/Footer";

function JackpotContent() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleBuyTicket = () => {
    navigate("/buyticket");
  };
  return (
    <div style={{ height: "100%" }}>
      <div className="main">
        <div className="concurse-main">
          <div className="info-content" style={{ padding: "0 0" }}>
            <div className="incoming-info" style={{ padding: "0 24px" }}>
              <img
                src={Logo2500}
                alt=""
                className="incoming-info-img position-size"
              />
              <BuyButton
                text="Купить билет"
                logo={buyBtnPrefix}
                func={handleBuyTicket}
              />
            </div>
            <div className="info-content-items">
              <div className="item-header">Джекпот</div>
              <div className="item-content">{t("Джекпот(раздел)")}</div>
            </div>
          </div>
        </div>
        {/*Second Content*/}
        <div className="second-content-main">
          {JackpotRecourseData.map((item: any) => (
            <MegaJackpotInfo image={item.image} text={item.content} />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default JackpotContent;
