import React from "react";

function BuyingTicketHeader (props:any) {
    return (
        <>
            <div className="buy-ticket-container-header">
                <div className="buy-ticket-container-left-side">
                    <img src={props.logo} alt=""/>
                </div>
                <div className="buy-ticket-container-right-side">
                    <div className="buy-ticket-container-right-side-header">
                        {props.headerTxt}
                    </div>
                    <div className="buy-ticket-container-right-side-content">
                        {props.contentTxt}
                    </div>
                </div>
            </div>
        </>
    )
}

export default BuyingTicketHeader;
