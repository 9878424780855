import React from "react";
import {BonuseData} from "../assets/images";
import MegaJackpotInfo from "./MegaJackpotInfo";
import {useTranslation} from "react-i18next";
import bonusLogo from "../assets/images/bonus_comp.png"
import Footer from "../auth/Footer";

function Bonuse() {
    const { t } = useTranslation();

    return (
        <div style={{height:"100%"}}>
            <div className="main">
                <div className="concurse-main">
                    <div className="info-content" style={{padding: "0 0"}}>
                            <img src={bonusLogo} alt="" className="bonuse-image incoming-info-img" />
                        <div className="info-content-items">
                            <div className="item-header">{t('Бонус')}</div>
                            <div className="item-content">
                                {t("Бонусы(раздел)")}
                            </div>
                        </div>

                    </div>
                </div>
                {/*Second Content*/}
                <div className="second-content-main">
                    {BonuseData.map((item:any)=>
                        <MegaJackpotInfo
                            image={item.image}
                            text= {item.content}
                        />
                    )}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Bonuse;
