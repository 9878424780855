import React from "react";
import { Carousel } from "antd";
import banner from "../assets/images/banner.svg";
import bannerMegawin from "../assets/images/banner-megawin.png";
import bannerJackpot from "../assets/images/banner-jackpot.png";

const contentStyle: React.CSSProperties = {
  background: "#1D1F2D",
  width: "100%",
};

const SlideItems = () => (
  <>
    <Carousel autoplay>
      <div>
        <div style={contentStyle}>
          <img src={bannerMegawin} alt="" className="carousel-item" />
        </div>
      </div>
      <div>
        <div style={contentStyle}>
          <img src={banner} alt="" className="carousel-item" />
        </div>
      </div>
      <div>
        <div style={contentStyle}>
          <img src={bannerJackpot} alt="" className="carousel-item" />
        </div>
      </div>
    </Carousel>
  </>
);

export default SlideItems;
