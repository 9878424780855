import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import StatisticTable from "./StatisticTable";
import Footer from "../auth/Footer";

function Statistic () {
    const [currentCycleNumber, setCurrentCycleNumber] = useState('')
    const [tableData, setTableData] = useState<any>([])
    const { t } = useTranslation()

    const getApiProfileCicleData = async () => {
        const response = await fetch(
            "https://cryptolottery777.com/public/user_info"
        ).then((response) => response.json()).then(res => {

            if (!res) {
                setTableData(res.data);
                setCurrentCycleNumber(
                    res.data[res.data.length - 1]["cycle_count"]
                ) 
            }
        });
    };
    useEffect(() => {
        getApiProfileCicleData()
    }, []);

    return (
        <div style={{height:"100%"}}>
        <div className="main" style={{height: "100%"}}>
            <div className="statistic-text">
                {t('Мы гордимся тем, что "Crypto Lottery 777" предоставляет выдающиеся возможности для выигрышей и незабываемых моментов для наших участников. Ниже вы найдете ключевую статистику, которая демонстрирует нашу прозрачность и доверие, которым мы обладаем.')}
            </div>
            <div className="profile-second-row">
                <StatisticTable tableData={tableData}/>
            </div>
        </div>
            <Footer />
        </div>
    );
}

export default Statistic;
