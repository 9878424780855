import React, { useEffect, useState } from "react";
import logo from "../assets/images/logo777.png";
import metamaskLogo from "../assets/images/metamask-logo.svg";
import ruFlag from "../assets/images/ru-flag.png";
import ConnectButton from "../buttons/ConnectButton";
import { useNavigate } from "react-router-dom";
import {
  addUserToMlm,
  generateString,
  getMegaJackpotAmount,
  getMegaJackpotStartTime,
  getSoldTicketCount,
  getUserInfo,
  getWinningTickets,
  meta,
  metamaskConnection,
} from "../scripts/web3";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { useSDK } from "@metamask/sdk-react";

function Header(props: any) {
  const [activeButton, setActiveButton] = useState("");
  const [walletAddress, setWalletAddress] = useState<any>("Подключить кошелек");
  const [tableData, setTableData] = useState<any>();
  const [inputData, setInputData] = useState("");
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [account, setAccount] = useState<string>();
  const { sdk, connected, connecting, provider, chainId } = useSDK();

  useEffect(() => {
    meta().then((res: any) => {
      if (res) {
        setWalletAddress(res?.slice(0, 6) + "..." + res?.slice(-6));
      } else setWalletAddress("Подключить кошелек");
    });
  }, [walletAddress]);

  const connect = async () => {
    try {
      const accounts: any = await sdk?.connect();
      if (accounts) {
        setWalletAddress(
          accounts[0].slice(0, 6) + "..." + accounts[0].slice(-6)
        );
      } else setWalletAddress("Подключить кошелек");

      setWalletAddress(accounts?.[0]);
      // window.location.reload();
      return accounts[0];
    } catch (err) {
      console.warn(`failed to connect..`, err);
    }
  };
  const handleChangeLanguage = (e: any) => {
    i18n.changeLanguage(e.target.value);
    checkbox.checked = false;
  };

  const handleHomePage = (buttonName: any) => {
    setActiveButton(buttonName);
    navigate("/");
  };
  const handlBonusesPage = (buttonName: any) => {
    setActiveButton(buttonName);
    checkbox.checked = false;
    navigate("/contests");
  };
  const handleStatisticPage = (buttonName: any) => {
    setActiveButton(buttonName);
    checkbox.checked = false;
    navigate("/statistic");
  };

  let checkbox: any = document.getElementById("chkb");
  const handleMegaJackpotPage = (buttonName: any) => {
    setActiveButton(buttonName);
    checkbox.checked = false;
    navigate("/megaJackpotContent");
  };

  const handleJackpotPage = (buttonName: any) => {
    setActiveButton(buttonName);
    checkbox.checked = false;
    navigate("/jackpotContent");
  };
  const handleProfilePage = async (buttonName: any) => {
    setActiveButton(buttonName);
    checkbox.checked = false
    props.setLoader(true);

    metamaskConnection().then((res) => {
      console.log("wallet_address in profile", res);

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ wallet_address: res }),
      };

      fetch("https://cryptolottery777.com/public/user_data", requestOptions)
        .then((response) => response.json())
        .then(async (response) => {
          await getUserInfo();
          setTableData(response.data[0]);
          if (res && response.data[0]?.referral_id_for_front) {
            navigate("/profile");
          } else {
            if (!response.data[0]?.referral_id_for_front && res) {
              (async () => {
                const { value: referalId } = await Swal.fire({
                  title: "Input Referal ID",
                  input: "text",
                  html: "If you don't have a referral ID, click OK to create it automatically.",
                  inputValue: 0,

                  inputPlaceholder: "input Referal ID",
                  showCancelButton: true,
                  inputValidator: (value) => {
                    return new Promise(async (resolve) => {
                      if (value === "0") {
                        await addUserToMlm(res, 0);
                        let genRandom = generateString(12);

                        const requestOptions1 = {
                          method: "POST",
                          headers: { "Content-Type": "application/json" },
                          body: JSON.stringify({
                            referral_id_for_front: genRandom,
                            wallet_address: res,
                          }),
                        };
                        fetch(
                          "https://cryptolottery777.com/public/referral_id_for_front",
                          requestOptions1
                        )
                          .then((response) => response.json())
                          .then((response) => {});
                        await getUserInfo();
                        navigate("/profile");
                        resolve();
                      } else if (value !== "0") {
                        setInputData(value);
                        let genRandom = generateString(12);

                        const requestOptions1 = {
                          method: "POST",
                          headers: { "Content-Type": "application/json" },
                          body: JSON.stringify({
                            referral_id_for_front: genRandom,
                            wallet_address: res,
                          }),
                        };
                        fetch(
                          "https://cryptolottery777.com/public/referral_id_for_front",
                          requestOptions1
                        )
                          .then((response) => response.json())
                          .then((response) => {});
                        await getUserInfo();

                        const requestOptions = {
                          method: "POST",
                          headers: { "Content-Type": "application/json" },
                          body: JSON.stringify({
                            referral_id: value,
                          }),
                        };

                        fetch(
                          "https://cryptolottery777.com/public/referral_id",
                          requestOptions
                        )
                          .then((response) => response.json())
                          .then(async (response) => {
                            if (response.length === 0) {
                              resolve("dsjgfdsgfdsjkfgdsjkfkdsj");
                            }
                            await addUserToMlm(res, Number(response[0]));
                            await getUserInfo();
                            navigate("/profile");
                          });
                        resolve();
                      } else {
                        resolve("ERROR");
                      }
                    });
                  },
                });
                if (referalId) {
                  Swal.fire("Inputed: " + referalId);
                }
              })();
            }
          }
        });
      props.setLoader(false);
    });
  };

  return (
    <nav className="navbar">
      <div className="navbar-container container">
        <input type="checkbox" name="" id="chkb" />
        <div className="hamburger-lines">
          <span className="line line1"></span>
          <span className="line line2"></span>
          <span className="line line3"></span>
        </div>
        <ul className="menu-items">
          <li>
            <span
              onClick={() => handleMegaJackpotPage("Мега Джекпот")}
              style={{
                color: activeButton === "Мега Джекпот" ? "#00BCFF" : "",
              }}
            >
              {t("Мега Джекпот")}
            </span>
          </li>
          <li>
            <span
              onClick={() => handleJackpotPage("Джекпот")}
              style={{ color: activeButton === "Джекпот" ? "#00BCFF" : "" }}
            >
              {t("Джекпот")}
            </span>
          </li>
          <li>
            <span
              onClick={() => handleStatisticPage("Статистика")}
              style={{ color: activeButton === "Статистика" ? "#00BCFF" : "" }}
            >
              {t("Статистика")}
            </span>
          </li>
          <li>
            <span
              onClick={() => handlBonusesPage("Бонуси")}
              style={{ color: activeButton === "Бонуси" ? "#00BCFF" : "" }}
            >
              {t("Бонуси")}
            </span>
          </li>
          <li>
            <span
              onClick={() => handleProfilePage("Профиль")}
              style={{ color: activeButton === "Профиль" ? "#00BCFF" : "" }}
            >
              {t("Профиль")}
            </span>
          </li>
          <li>
            <select className="flag-container" onChange={handleChangeLanguage}>
              <option value="ru" prefix={ruFlag}>
                RU
              </option>
              <option value="en" style={{ backgroundImage: "ruFlag" }}>
                EN
              </option>
            </select>
          </li>
        </ul>
        <div className="desktop-logo">
          <img
            src={logo}
            alt="logo"
            onClick={() => handleHomePage("Главная страница")}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="logo">
          <div className="mobile-logo">
            <img
              src={logo}
              alt="logo"
              onClick={() => handleHomePage("Главная страница")}
              style={{ cursor: "pointer" }}
            />
          </div>
          <ConnectButton
            text={t(walletAddress)}
            logo={metamaskLogo}
            func={getSoldTicketCount}
          />
          {/*</div>*/}
        </div>
      </div>
    </nav>
  );
}

export default Header;
