import React from "react";

function Ticket(props: any) {
    return (
        <div className="ticket-main">
            <div className="ticket-main-content">
            {props.isView?
                <div className="competition-name-header-status-ticket">
                {props.index < props.responseData?.length - props.lastItem?.length ?
                    <span className="competition-name-header-status-span-end">Оконченный</span> :
                    <span className="competition-name-header-status-span-corrent">Tекущий</span>}
            </div>:""}
            <div className="ticket-main-header">
                <div>
                    <div className="competition-name-content-header">
                        <span className="ticket-text">{props.contentHeader}</span>
                    </div>
                    <div className="competition-name-content-ticket">
                        <span className="ticket-content-text">{props.contentText}</span>
                    </div>
                </div>
                {props.index < props.responseData?.length - props.lastItem?.length?
                    <div style={{marginTop: "-70px", marginRight: "-30px"}}><img src={props.winnerLogo} alt=""/>
                </div>:""}
            </div>
            <div className="ticket-main-footer">
                <div className="ticket-main-left">
                    <span>#{props.buttonText}</span>
                </div>
                <div className="result-ticket" onClick={props.func}>
                    {props.resultText}
                </div>
            </div>
            </div>
        </div>
    )
}

export default Ticket;
