import React from "react";
import {useTranslation} from "react-i18next";

function MegaJackpotInfo (props:any) {
    const { t } = useTranslation();

    return (
        <div className="megajackpot-info-main">
            <img src={props.image} alt="image"/>
            <div className="megajackpot-info-text">
                <span>{props.text}</span>
            </div>
        </div>
    )
}

export default MegaJackpotInfo;
