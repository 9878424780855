import React from "react";
import {useTranslation} from "react-i18next";

function ProfileTable (props:any) {
    const { t } = useTranslation()

    return (
        <>
            <div className="details-header">
                <span>{t('Бонусы')}</span>
            </div>
            <div className="statistic-table-scroll">
            <table>
                <tr>
                    <th>{t('Название конкурса')}</th>
                    <th>{t('Адрес кошелька')}</th>
                    <th>{t('Дата')}</th>
                    <th>{t('Бонус')}</th>
                </tr>
                {props.tableData &&
                    props.tableData.map((val:any, key:any) => {
                    return (
                            <tr key={key}>
                                <td>{val.event_name}</td>
                                <td>{`${val?.wallet_address.slice(0,4)}...${val?.wallet_address.slice(-5)}`}</td>
                                <td>{new Date(val.created_at).toLocaleDateString()}</td>
                                <td className="last-item-td">+{val?.bonus_amount / 10**18} USDT</td>
                            </tr>
                    )
                })}
            </table>
            </div>
        </>
    );
}

export default ProfileTable;
